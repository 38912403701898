#main {
	height: calc(100vh - 64px);
}
#article {
	grid-area: article;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	background: #ffffff;
	height: 100%;
}
#article #header #h3 {
	font-weight: 500;
	text-align: center;
	color: #333333;
	font-family: 'EB Garamond', serif;
}
#row {
	height: 100%;
}
#container-fluid {
	height: 100%;
}
#aside {
	grid-area: aside;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	background: #eeeeee;
	padding: 0 16px;
	height: 100%;
}
#row > #aside > #card {
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding-bottom: 16px;
}
#row > #aside > #h1 {
	font-family: Roboto;
	margin: 8px 0;
	font-size: xx-large;
	text-align: center;
	font-weight: 500;
}
#user,
#password {
	border-bottom: none !important;
	box-shadow: none !important;
}

#formControlRadios {
	margin-top: 16px;
}
