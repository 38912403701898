#main {
	/* background-color: aqua; */
}
#textArea {
	height: 10em;
	padding: 16px;
	font-size: 12pt;
	font-family: Roboto;
}
.labels {
	color: #555555;
	font-size: 11pt;
	font-weight: 500;
}
#main > header > h4 {
	text-align: center;
}
#main > section {
	background-color: #00000005;
	padding: 16px 32px 32px 32px;
}
.titleDialog {
	font-size: 16pt;
	font-weight: 500;
}
.infoDialog {
	font-size: 14pt;
	font-weight: 700;
}
#dialogLink {
	margin-top: 16px;
}
#dialogTitle {
	margin-bottom: 32px;
}
main.card {
	padding: 32px;
	background-color: #0000001f;
}
